<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Work Assignment List">
      <b-button
        v-show="isAdmin"
        variant="outline-primary"
        class="bg-gradient-primary mt-2 side-button"
        type="submit"
        @click="openModal()"
      >
        <span class="align-middle">Add New Assignment</span>
      </b-button>

      <app-collapse class="filter mt-1">
        <app-collapse-item title="Filter">

          <b-row class="match-height mb-2">
            <b-col md="6">
              <b-form-group
                label="Date Range"
                label-for="Role"
              >
                <date-range-picker
                  ref="filter"
                  v-model="filterDate"
                  :opens="'right'"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                >
                  <template
                    v-slot:input="picker"
                    style="min-width: 350px;"
                  >
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="User"
                label-for="User"
              >
                <v-select
                  v-model="filterUser"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select User"
                  :options="optionsWorker"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Area"
                label-for="Area"
              >
                <v-select
                  v-model="filterArea"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Area"
                  :options="optionsArea"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Work"
                label-for="Work"
              >
                <v-select
                  v-model="filterWork"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Work"
                  :options="optionsWork"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Status"
                label-for="Status"
              >
                <v-select
                  v-model="filterStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Area"
                  :options="optionsStatus"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-button
              variant="outline-primary"
              class="bg-gradient-grey mr-1"
              type="submit"
              @click="resetFilter()"
            >
              <span class="align-middle">Reset</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="bg-gradient-primary mr-1"
              type="submit"
              @click="getDisposisi"
            >
              <span class="align-middle">Filter</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="bg-gradient-primary mr-1"
              type="submit"
              @click="exportData"
            >
              <span class="align-middle">Export CSV</span>
            </b-button>

          </div>
        </app-collapse-item>
      </app-collapse>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="dataRows"
        :is-loading="loading"
        style-class="vgt-table striped"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom'
        }"
      >
        <div
          slot="emptystate"
          align="center"
        >
          No Data
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'Status'"
            :title="props.row.Status"
          >
            <b-badge :variant="statusVariant(props.row.Status)">
              {{ props.row.Status }}
            </b-badge>
          </span>

          <!-- Column: Approve -->
          <span
            v-else-if="props.column.field === 'Approve'"
            :title="props.row.Approve"
          >
            <b-badge :variant="ApproveVariant(props.row.Approve)">
              {{ props.row.Approve === 0 ? 'Waiting ' : 'Done' }}
            </b-badge>
          </span>

          <!-- Column: Approve -->
          <span
            v-else-if="props.column.field === 'StartDate'"
            :title="props.row.StartDate"
          >
            {{ dateFormat(props.row.StartDate) }} - {{ dateFormat(props.row.EndDate) }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none nopad"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <b-dropdown-item @click="detail({id: props.row.id, order: 'edit'})">
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>View Detail</span>
                </b-dropdown-item>

                <b-dropdown-item @click="detail({id:props.row.id, order: 'delete'})">
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','15','30','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>

            <div>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2"
                  pill
                  :disabled="!prev"
                  size="sm"
                  @click="prev ? getDisposisi('prev') : ''"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </b-button>
              </template>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2 ml-1"
                  pill
                  :disabled="!next"
                  size="sm"
                  @click="next ? getDisposisi('next') : ''"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </b-button>
              </template>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-center"
        ref="modal"
        v-model="modalShow"
        centered
        :title="`Add Work Assignment`"
        cancel-title="Cancel"
        ok-title="Add"
        @ok="validationForm"
      >
        <b-form-group
          label="Date"
          label-for="Role"
          class="mt-2"
        >
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            :opens="'center'"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          >
            <template
              v-slot:input="picker"
              style="min-width: 350px;"
            >
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </date-range-picker>

        </b-form-group>

        <validation-provider
          #default="{ errors }"
          name="Worker"
          rules="required"
          class="mb-2"
        >
          <b-form-group
            label="Worker"
            label-for="Worker"
          >
            <v-select
              v-model="selectedWorker"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Select Worker"
              :options="optionsWorker"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Area"
          rules="required"
          class="mb-2"
        >
          <b-form-group
            label="Area"
            label-for="Area"
          >
            <v-select
              v-model="selectedArea"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Select Area"
              :options="optionsArea"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Work"
          rules="required"
          class="mb-2"
        >
          <b-form-group
            label="Work"
            label-for="Work"
          >
            <v-select
              v-model="selectedWork"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Select Work"
              :options="optionsWork"
              multiple
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-modal>

      <!-- MODAL DETAIL -->
      <b-modal
        id="modal-detail"
        ref="modal-detail"
        v-model="modalDetail"
        size="lg"
        centered
        :title="`Detail Todo Work`"
        cancel-title="Cancel"
        ok-title="Save"
        @ok="validationEdit"
      >
        <b-row class="match-height">
          <b-col md="6">
            <b-form-group
              label="Date"
              label-for="Role"
            >
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                :opens="'center'"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              >
                <template
                  v-slot:input="picker"
                  style="min-width: 350px;"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
              </date-range-picker>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Worker"
              rules="required"
            >
              <b-form-group
                label="Worker"
                label-for="Worker"
              >
                <v-select
                  v-model="selectedWorker"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Worker"
                  :options="optionsWorker"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Area"
              rules="required"
            >
              <b-form-group
                label="Area"
                label-for="Area"
              >
                <v-select
                  v-model="selectedArea"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Area"
                  :options="optionsArea"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Work"
              rules="required"
            >
              <b-form-group
                label="Work"
                label-for="Work"
              >
                <v-select
                  v-model="selectedWork"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Work"
                  :options="optionsWork"
                  multiple
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col md="6">
            <b-form-group
              label="Finish Date"
              label-for="Role"
            >
              <b-form-datepicker
                id="example-datepicker"
                v-model="datePicker"
                class="mb-1"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            style="flex-flow: nowrap;align-items: center;justify-content: space-between;"
          >
            <b-form-group
              label="Finish Time"
              label-for="Role"
              style="flex-grow: 1;margin-right: 10px;"
            >
              <b-form-timepicker
                id="timepicker-buttons"
                v-model="timePicker"
                now-button
                :hour12="false"
                reset-button
                locale="en"
              />
            </b-form-group>
            <b-button
              variant="outline-danger"
              class="bg-gradient-danger"
              type="submit"
              style="height: 49px;"
              @click="updateChecklist('delete')"
            >
              <span class="align-middle">X</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col md="4">
            <b-form-group
              label="Photo Before"
              label-for="Photo Before"
            >
              <div
                v-if="toggleBefore"
                class="preview"
              >
                <img
                  :src="`https://api.arginipratamaputra.com${viewBefore}`"
                  @click="openFile(viewBefore)"
                >
              </div>

              <b-form-file
                v-else
                id="FotoBefore"
                ref="fileBefore"
                v-model="fileBefore"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileBeforeChange"
              />

              <div
                v-show="viewBefore !== '' && viewBefore !== null"
                class="change"
                @click="toggleBefore = !toggleBefore"
              >
                <span> {{ toggleBefore ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Photo Proggress"
              label-for="Photo Proggress"
            >
              <div
                v-if="toggleProggress"
                class="preview"
              >
                <img
                  :src="`https://api.arginipratamaputra.com${viewProggress}`"
                  @click="openFile(viewProggress)"
                >
              </div>

              <b-form-file
                v-else
                id="FotoProggress"
                ref="fileProggress"
                v-model="fileProggress"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileProggressChange"
              />

              <div
                v-show="viewProggress !== '' && viewProggress !== null"
                class="change"
                @click="toggleProggress = !toggleProggress"
              >
                <span> {{ toggleProggress ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Photo After"
              label-for="Photo After"
            >
              <div
                v-if="toggleAfter"
                class="preview"
              >
                <img
                  :src="`https://api.arginipratamaputra.com${viewAfter}`"
                  @click="openFile(viewAfter)"
                >
              </div>

              <b-form-file
                v-else
                id="FotoAfter"
                ref="filAfter"
                v-model="fileAfter"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileAfterChange"
              />

              <div
                v-show="viewAfter !== '' && viewAfter !== null"
                class="change"
                @click="toggleAfter = !toggleAfter"
              >
                <span> {{ toggleAfter ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col md="6">
            <label for="textarea-default">Notes</label>
            <b-form-textarea
              id="textarea-default"
              v-model="keterangan"
              placeholder="Add Notes"
              rows="3"
            />
          </b-col>
          <b-col md="6">

            <validation-provider
              #default="{ errors }"
              name="Score"
              rules=""
            >
              <b-form-group
                label="Score"
                label-for="Score"
              >
                <b-form-input
                  id="Score"
                  v-model="Score"
                  placeholder="Add Score"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <b-form-group
              label="Approve"
              label-for="Approve"
            >
              <b-form-checkbox
                v-model="Approve"
                checked="false"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <label class="ml-2">Updated at: </label>
          <b> {{ dateFormat(updatedAt) }} </b>
        </b-row>
      </b-modal>

      <b-modal
        id="modal-detete"
        ref="modaldelete"
        v-model="modalDelete"
        centered
        :title="`Remove Work Assignment`"
        cancel-title="Cancel"
        :ok-title="'Remove' "
        @ok="deletes"
      >
        <span> Remove work assignment area <b> {{ selectedArea.text }} </b> </span>
      </b-modal>

      <div
        v-if="loadingImage"
        class="load-image"
      >
        <b-spinner
          class="mr-1"
          variant="primary"
        />
      </div>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import moment from 'moment'
import {
  BSpinner,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
// import dayjs from 'dayjs'

import store from '@/store/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
// import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

Vue.prototype.moment = moment

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    required,
    email,
    numeric,
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    vSelect,
    BBadge,
    BFormFile,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BFormTextarea,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    BFormCheckbox,
    ToastificationContent,
    BRow,
    BCol,
    BFormTimepicker,
    DateRangePicker,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    date(val) {
      return val ? moment(val).format('DD/MM/YYYY') : ''
    },
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      currentPage: null,
      filterUser: [],
      filterArea: [],
      filterWork: [],
      filterStatus: [],
      datePicker: null,
      timePicker: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      filterDate: {
        startDate: null,
        endDate: null,
      },
      pageLength: 0,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      finish: false,
      loadingImage: false,
      viewBefore: null,
      viewProggress: null,
      viewAfter: null,
      fileAfter: null,
      fileProggress: null,
      fileBefore: null,
      toggleBefore: false,
      toggleProggress: false,
      toggleAfter: false,
      isAdmin: false,
      fileName: '',
      keterangan: '',
      Score: 0,
      page: 0,
      selectedId: 0,
      selectedWork: [],
      selectedWorker: [],
      selectedArea: [],
      selectedUserID: '',
      selectedAreaID: '',
      fromPage: 0,
      toPage: 0,
      prev: '',
      next: '',
      updatedAt: '',
      modalShow: false,
      modalDetail: false,
      modalDelete: false,
      Approve: false,
      optionsWork: [],
      optionsWorker: [],
      optionsArea: [],
      optionsStatus: [
        { value: 'PROCESS', text: 'Process' },
        { value: 'DONE', text: 'Done' },
        { value: 'LATE', text: 'Late' },
      ],
      columns: [
        {
          label: 'Area',
          field: 'Area',
        },
        {
          label: 'Worker',
          field: 'Worker',
        },
        {
          label: 'Date',
          field: 'StartDate',
        },
        {
          label: 'Status',
          field: 'Status',
        },
        {
          label: 'Approve',
          field: 'Approve',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          Area: '',
          Worker: '',
          Date: '',
          Note: '',
          Action: '',
        },
      ],
      json_data: [
        {
          name: 'Tony Peña',
          city: 'New York',
          country: 'United States',
          birthdate: '1978-03-15',
          phone: {
            mobile: '1-541-754-3010',
            landline: '(541) 754-3010',
          },
        },
        {
          name: 'Thessaloniki',
          city: 'Athens',
          country: 'Greece',
          birthdate: '1987-11-23',
          phone: {
            mobile: '+1 855 275 5071',
            landline: '(2741) 2621-244',
          },
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Not Done',
          2: 'Finish',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        DONE: 'light-success',
        LATE: 'light-danger',
        Resigned: 'light-warning',
        PROCESS: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    ApproveVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        hh: 'light-primary',
        1: 'light-success',
        LATE: 'light-danger',
        Resigned: 'light-warning',
        0: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    this.getDisposisi()
    this.getWork()
    this.getArea()
    this.getWorker()
  },
  methods: {
    openModal() {
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.selectedWork = []
      this.selectedWorker = []
      this.selectedArea = []
      this.modalShow = true
    },
    async fileBeforeChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileBefore = data
      this.loadingImage = false
    },
    async fileProggressChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileProggress = data
      this.loadingImage = false
    },
    openFile(val) {
      window.open(`https://api.arginipratamaputra.com${val}`, '_blank')
    },
    async fileAfterChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileAfter = data
      this.loadingImage = false
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addAssignment()
        }
      })
    },
    validationEdit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateWork()
          this.updateApprove()
        }
      })
    },

    async getWork() {
      const { data } = await axios.get('/work')
      this.optionsWork = data.data.map(e => ({
        value: e.id,
        text: e.work,
      }))
    },
    async getArea() {
      const { data } = await axios.get('/area')
      this.optionsArea = data.data.map(e => ({
        value: e.id,
        text: e.area,
      }))
    },
    async getWorker() {
      const { data } = await axios.get('/user')
      this.optionsWorker = data.data.map(e => ({
        value: e.id,
        text: e.nama,
      }))
    },
    resetFilter() {
      this.filterDate = {
        startDate: null,
        endDate: null,
      }
      this.filterUser = []
      this.filterArea = []
      this.filterWork = []
      this.filterStatus = []
      this.getDisposisi()
    },
    async detail(val) {
      const { data } = await axios.get('/work/assignment', {
        params: {
          id: val.id,
        },
      })

      this.viewBefore = data.data[0].foto
      this.toggleBefore = data.data[0].foto !== '' && data.data[0].foto !== null
      this.fileBefore = data.data[0].foto

      this.viewProggress = data.data[0].foto_progress
      this.toggleProggress = data.data[0].foto_progress !== '' && data.data[0].foto_progress !== null
      this.fileProggress = data.data[0].foto_progress

      this.viewAfter = data.data[0].foto_done
      this.toggleAfter = data.data[0].foto_done !== '' && data.data[0].foto_done !== null
      this.fileAfter = data.data[0].foto_done

      this.dateRange.startDate = data.data[0].start_date
      this.dateRange.endDate = data.data[0].end_date

      this.datePicker = data.data[0].checklist_time
      this.timePicker = dayjs(data.data[0].checklist_time).format('HH:mm')

      this.selectedWorker = {
        value: data.data[0].user !== null ? data.data[0].user.id : '',
        text: data.data[0].user !== null ? data.data[0].user.nama : '',
      }
      this.selectedArea = {
        value: data.data[0].area !== null ? data.data[0].area.id : '',
        text: data.data[0].area !== null ? data.data[0].area.area : '',
      }
      if (data.data[0].work[0] !== null) {
        this.selectedWork = data.data[0].work.map(y => ({
          value: y.id,
          text: y.work,
        }))
      }

      this.Score = data.data[0].nilai
      this.keterangan = data.data[0].keterangan
      this.Approve = data.data[0].is_approved === 1
      this.updatedAt = data.data[0].updated_at
      this.selectedId = data.data[0].id

      if (val.order === 'edit') {
        this.modalDetail = true
      } else if (val.order === 'delete') {
        this.modalDelete = true
      }
    },
    async exportData() {
      this.loadingImage = true
      axios({
        url: '/export',
        method: 'GET',
        params: {
          startDate:
            this.filterDate.startDate !== null
              ? dayjs(this.filterDate.startDate).format('YYYY-MM-DD 00:00:00')
              : undefined,
          endDate:
            this.filterDate.endDate !== null
              ? dayjs(this.filterDate.endDate).format('YYYY-MM-DD 23:59:59')
              : undefined,
        },
        responseType: 'blob',
      }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'file.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
        this.loadingImage = false
      })
    },
    async getDisposisi(val) {
      const defaultUrl = '/work/assignment'
      let url = ''

      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else if (val) {
        if (val.order === 'get2') {
          url = `/work/assignment?page=${val.page}`
        } else {
          url = defaultUrl
        }
      } else {
        url = defaultUrl
      }

      const { data } = await axios.get(url, {
        params: {
          startDate:
            this.filterDate.startDate !== null
              ? dayjs(this.filterDate.startDate).format('YYYY-MM-DD 00:00:00')
              : undefined,
          endDate:
            this.filterDate.endDate !== null
              ? dayjs(this.filterDate.endDate).format('YYYY-MM-DD 23:59:59')
              : undefined,
          user_id: this.filterUser !== null ? this.filterUser.value : undefined,
          area_id: this.filterArea !== null ? this.filterArea.value : undefined,
          'work_id[]':
            this.filterWork !== null ? this.filterWork.value : undefined,
          status:
            this.filterStatus !== null ? this.filterStatus.value : undefined,
        },
      })
      this.prev = data.prev_page_url
      this.next = data.next_page_url
      this.totalPage = data.total
      this.currentPage = data.current_page
      // this.totalRow = data.per_page
      this.fromPage = data.from
      this.toPage = data.to
      this.pageLength = data.per_page
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          Area: e.area ? e.area.area : '-',
          Worker: e.user ? e.user.nama : '-',
          StartDate: e.start_date,
          EndDate: e.end_date,
          Status: e.status || '-',
          Approve: e.is_approved,
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      // .catch(error => {
      //   console.log(error)
      // })
    },
    async addAssignment() {
      await axios.post('work/assignment', {
        work_id: this.selectedWork.map(e => e.value),
        user_id: this.selectedWorker.value,
        area_id: this.selectedArea.value,
        start_date: dayjs(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: dayjs(this.dateRange.endDate).format('YYYY-MM-DD '),
      })
      this.getDisposisi()
    },

    async updateWork() {
      await axios.post('work/assignment/update', {
        work_id: this.selectedWork.map(e => e.value),
        user_id: this.selectedWorker.value,
        area_id: this.selectedArea.value,

        checklist_time:
          this.datePicker !== null
            ? `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
              this.timePicker
            }`
            : null,
        // done_date:
        //   this.datePicker !== null
        //     ? `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
        //       this.timePicker
        //     }`
        //     : null,
        foto: this.fileBefore,
        foto_progress: this.fileProggress,
        foto_done: this.fileAfter,

        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        keterangan: this.keterangan,
        id: this.selectedId,
      })
      if (this.datePicker !== null) {
        this.updateChecklist()
      }
      this.getDisposisi({ order: 'get2', page: this.currentPage })
    },

    async updateChecklist(val) {
      let value = {}
      if (val === 'delete') {
        value = {
          id: this.selectedId,
          checklist: 0,
          checklist_time: null,
        }
      } else {
        value = {
          id: this.selectedId,
          checklist: this.finish,
          checklist_time:
            this.datePicker !== null
              ? `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
                this.timePicker
              }`
              : null,
        }
      }
      await axios
        .post('work/checklist', value)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi({ order: 'get2', page: this.currentPage })
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async updateApprove() {
      await axios.post('todo/approved', {
        id: this.selectedId,
        approved: this.Approve ? 1 : 0,
        nilai: this.Score,
      })
    },

    async deletes() {
      await axios
        .delete(`work/assignment/${this.selectedId}`)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
.vue-daterange-picker {
  width: 100%;
  .reportrange-text {
    display: flex;
    align-items: center;
  }
}

.filter {
  .card-header {
    background: #ededed;
  }
  .card-body {
    border: solid 1px #ededed;
    border-bottom: unset;
  }
}

.preview {
  img {
    width: 100%;
    display: block;
    margin: auto;
  }
}

.change {
  text-align: center;
  display: flex;
  padding: 9px 0 3px;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  color: #00427a;
  span {
    border-bottom: solid 1px #00427a;
  }
}
</style>
